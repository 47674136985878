<template>
  <v-card elevation="2" color="background" class="d-flex flex-column">
    <!-- <v-card-title> الاعدادات </v-card-title> -->
    <v-card-text style="flex: 1">
      <v-row class="fill-height">
        <v-col class="fill-height">
          <v-card elevation="0" outlined class="fill-height">
            <v-tabs
              class="pa-5 font-weight-black"
              active-class="primary lighten-1 white--text"
            >
              <v-tab>
                <v-icon> mdi-account </v-icon>
                الملف الشخصي
              </v-tab>
              <v-tab>
                <v-icon> mdi-lock </v-icon>
                كلمة المرور
              </v-tab>

              <v-tab-item>
                <v-card flat elevation="0">
                  <v-card-text>
                    <profile />
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat elevation="0">
                  <v-card-text>
                    <reset-password />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Profile from './profile/Profile.vue';
import ResetPassword from './resetPassword/ResetPassword.vue';

export default {
  components: {
    Profile,
    ResetPassword,
  },
  created() {
    this.$store.commit('theme/TOGGLE_DRAWER', false);
  },
  destroyed() {
    this.$store.commit('theme/TOGGLE_DRAWER', true);
  },
};
</script>

<style lang="scss" scoped></style>
