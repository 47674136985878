<template>
  <form>
    <v-card elevation="0">
      <v-container fluid>
        <v-form ref="form" v-model="isValid" lazy-validation>
          <!-- 
          <v-row>
            <v-col>
              <v-text-field v-model="email" label="الايميل" :disabled="true" />
            </v-col>
            <v-col>
              <v-text-field
                v-model="userName"
                label="اسم المستخدم"
                :disabled="true"
              />
            </v-col>
          </v-row>
         -->

          <v-row>
            <v-col>
              <v-list class="list-card">
                <v-subheader> اسم المستخدم </v-subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="headline" v-html="userName" />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col>
              <v-list class="list-card">
                <v-subheader> الايميل </v-subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="headline" v-html="email" />
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-chip
                v-for="(item, index) in roles"
                :key="index"
                class="ma-2"
                color="green darken-2"
                label
                text-color="white"
              >
                <v-icon class="ml-2">mdi-check-all</v-icon>
                <span class="title">
                  {{ item }}
                </span>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="name"
                label="الاسم"
                required
                outlined
                :counter="50"
                :error-messages="nameErrors"
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </v-col>
            <v-col cols="12" v-show="false">
              <v-text-field
                v-model="surname"
                label="surname"
                required
                outlined
                :counter="50"
                :disabled="isLoading"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="phoneNumber"
                label="رقم الهاتف"
                style="direction: ltr"
                required
                outlined
                :counter="16"
                v-mask="'(####) ### ########'"
                :error-messages="phoneNumberErrors"
                @input="$v.phoneNumber.$touch()"
                @blur="$v.phoneNumber.$touch()"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>

      <v-container fluid>
        <v-row class="justify-end">
          <v-col sm="12" md="4" lg="2">
            <v-btn
              color="primary"
              dark
              large
              :loading="isLoading"
              @click="submit"
              block
            >
              <v-icon color="#fff">mdi-content-save-outline</v-icon>
              حفظ
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { validationMixin } from 'vuelidate';
import {
  required,
  maxLength,
  minLength,
  email,
} from 'vuelidate/lib/validators';

import {
  showSnackbarFailMessage,
  showSnackbarSuccessMessage,
  showSnackbarWarningMessage,
} from '@/utls/snackbar';

const getDefaultState = () => ({
  isLoading: false,
  isValid: true,
  userName: '',
  email: '',
  name: '',
  surname: '',
  phoneNumber: '',
});

export default {
  mixins: [validationMixin],
  validations() {
    return {
      name: { required, maxLength: maxLength(50) },
      phoneNumber: { maxLength: maxLength(16) },
    };
  },
  data: () => ({ ...getDefaultState() }),
  async created() {
    this.fillUser();
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.user && state.user.userData.user,
    }),
    roles() {
      return this.currentUser.roles || [];
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength && errors.push('لايمكن تجاوز الحد المسموح');
      !this.$v.name.required && errors.push('الاسم مطلوب');
      return errors;
    },
    phoneNumberErrors() {
      const errors = [];
      if (!this.$v.phoneNumber.$dirty) return errors;
      !this.$v.phoneNumber.maxLength &&
        errors.push('لايمكن تجاوز الحد المسموح');
      return errors;
    },
  },
  methods: {
    async fillUser() {
      const currentUser = await this.getCurrentUser();
      if (currentUser == null) return;
      for (const k in getDefaultState()) {
        const value = currentUser[k];
        if (value == undefined) continue;
        this.$data[k] = value;
      }
    },
    async getCurrentUser() {
      this.isLoading = true;
      return this.$http
        .get('account/my-profile')
        .then((response) => response.data)
        .catch((error) => {
          showSnackbarFailMessage('حدث خطأ اثناء عملية جلب البيانات');
          return null;
        })
        .finally(() => (this.isLoading = false));
    },
    isInvalid() {
      const isValidForm = this.$refs.form.validate();
      this.$v.$touch();
      const isInvalidValidators = this.$v.$invalid;
      if (!isValidForm || isInvalidValidators) return true;
      return false;
    },
    submit() {
      if (this.isInvalid()) {
        showSnackbarWarningMessage('يجب عدم ترك الاعمدة المطلوبة فارغة');
        return;
      }

      const userName = this.currentUser.userName;
      const name = this.name;
      const surname = this.surname;
      const email = this.currentUser.email;
      const phoneNumber = this.phoneNumber;
      const postData = {
        userName,
        name,
        surname,
        email,
        phoneNumber,
      };

      this.isLoading = true;
      const failMsg = 'حدث خطأ اثناء عملية حفظ البيانات';
      this.$http
        .put('account/my-profile', { ...postData })
        .then((response) => {
          if (response.status != 200) return showSnackbarFailMessage(failMsg);
          showSnackbarSuccessMessage('تم الحفظ بنجاح');
        })
        .catch((error) => showSnackbarFailMessage(failMsg))
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.list-card {
  border: 1.5px dotted grey;
}
</style>
